import React, { useState, useEffect } from "react";
import config from "./config"; // Assuming the API key and base URL are stored in config.js


const FormPage = () => {
  const [formData, setFormData] = useState({
    birthday: "2022-02-02",
    email: "",
    gender: "M",
    name: "",
    otp: "123456",
    password: "1234567890Q",
    phone: "",
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  // Utility functions to generate random data
  const generateRandomPhone = () => {
    const firstPart = Math.floor(100 + Math.random() * 900); // Always 4 digits
    const secondPart = Math.floor(100 + Math.random() * 900); // Always 3 digits
    return `0912-${firstPart}-${secondPart}`;
  };

  const generateRandomEmail = () => `user_${Math.floor(Math.random() * 10000)}@example.com`;
  const generateRandomName = () => `使用者_${Math.floor(Math.random() * 10000)}`;

  // Initialize random fields on component mount
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      phone: generateRandomPhone(),
      email: generateRandomEmail(),
      name: generateRandomName(),
    }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");

    try {
      // Step 1: Get Access Token
      const tokenResponse = await fetch(`${config.BASE_URL}/oauth/verify`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          apiKey: config.API_KEY,
          scope: [""],
          tokenType: "Bearer",
        }),
      });

      const tokenData = await tokenResponse.json();
      if (!tokenData.success) throw new Error("獲取 access token 失敗");

      const accessToken = tokenData.data.accessToken;

      // Step 2: Send OTP (Remove dashes from phone number)
      const otpResponse = await fetch(`${config.BASE_URL}/client/otp/sms`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          phone: formData.phone.replace(/-/g, ""), // Remove dashes here
          type: "signUp",
        }),
      });

      if (!otpResponse.ok) {
        const errorDetails = await otpResponse.json();
        console.error("傳送 OTP 發生錯誤:", errorDetails);
        throw new Error(`傳送 OTP 失敗: ${errorDetails.message || "未知錯誤"}`);
      }

      // Step 3: Register User (Remove dashes from phone number)
      const registerResponse = await fetch(`${config.BASE_URL}/client/sign-up`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ ...formData, phone: formData.phone.replace(/-/g, "") }), // Remove dashes here
      });

      if (!registerResponse.ok) {
        const errorDetails = await registerResponse.json();
        console.error("註冊用戶發生錯誤:", errorDetails);
        throw new Error(`註冊用戶失敗: ${errorDetails.message || "未知錯誤"}`);
      }

      setMessage("用戶註冊成功！");
    } catch (error) {
      console.error("發生錯誤:", error); // Log unexpected errors
      setMessage(`錯誤: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <div style={styles.page}>
      <h1 style={styles.header}>用戶註冊表單</h1>
      {message && <p style={styles.message}>{message}</p>}
      <form style={styles.form} onSubmit={handleSubmit}>
        <div style={styles.formGroup}>
          <label style={styles.label}>
            生日：
            <input
              type="date"
              name="birthday"
              value={formData.birthday}
              onChange={handleChange}
              style={styles.input}
            />
          </label>
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>
            電子郵件：
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              style={styles.input}
            />
          </label>
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>
            性別：
            <select
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              style={styles.input}
            >
              <option value="M">男性</option>
              <option value="F">女性</option>
            </select>
          </label>
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>
            姓名：
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              style={styles.input}
            />
          </label>
        </div>
        {/* <div style={styles.formGroup}>
          <label style={styles.label}>
            驗證碼：
            <input
              type="text"
              name="otp"
              value={formData.otp}
              onChange={handleChange}
              style={styles.input}
            />
          </label>
        </div> */}
        {/* <div style={styles.formGroup}>
          <label style={styles.label}>
            密碼：
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              style={styles.input}
            />
          </label>
        </div> */}
        <div style={styles.formGroup}>
          <label style={styles.label}>
            手機號碼：
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              style={styles.input}
            />
          </label>
        </div>
        <button type="submit" style={styles.button} disabled={loading}>
          {loading ? "處理中..." : "提交"}
        </button>
      </form>
    </div>
  );
};

// Inline Styles
const styles = {
  page: {
    fontFamily: "Arial, sans-serif",
    margin: 0,
    padding: "20px",
    backgroundColor: "#f9f9f9",
    color: "#333",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    marginTop: "20px",
    color: "#2c3e50",
  },
  form: {
    maxWidth: "600px",
    width: "100%",
    marginTop: "20px",
    padding: "20px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
  },
  formGroup: {
    marginBottom: "15px",
  },
  label: {
    display: "block",
    marginBottom: "5px",
    fontWeight: "bold",
    color: "#34495e",
  },
  input: {
    width: "100%",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontSize: "16px",
  },
  button: {
    width: "100%",
    padding: "12px",
    fontSize: "16px",
    fontWeight: "bold",
    backgroundColor: "#3498db",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  message: {
    color: "#2ecc71",
    fontWeight: "bold",
    textAlign: "center",
  },
};

export default FormPage;
